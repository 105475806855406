"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWEventToJSON = exports.OWEventFromJSONTyped = exports.OWEventFromJSON = void 0;
var _1 = require("./");
function OWEventFromJSON(json) {
    return OWEventFromJSONTyped(json, false);
}
exports.OWEventFromJSON = OWEventFromJSON;
function OWEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'eventInfo': json['eventInfo'],
        'gameId': json['gameId'],
        'userId': json['userId'],
        'timestamp': (new Date(json['timestamp'])),
        'category': (0, _1.OWLogCategoryFromJSON)(json['category']),
    };
}
exports.OWEventFromJSONTyped = OWEventFromJSONTyped;
function OWEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'eventInfo': value.eventInfo,
        'gameId': value.gameId,
        'userId': value.userId,
        'timestamp': (value.timestamp.toISOString()),
        'category': (0, _1.OWLogCategoryToJSON)(value.category),
    };
}
exports.OWEventToJSON = OWEventToJSON;
